import { useState, useRef, useEffect} from "react";
import { IconButton, TextField } from '@mui/material';
import { gameState } from "../common/models";
import GameView from './GameView'
import GameCreateJoin from "./GameCreateJoin";
import { makeGetRequest } from "../common/helpers";
import { WS_BASE_URL } from "../common/constants";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useStyles, sxStyles } from "../common/styles";
import ZaffoWaffo from "./ZaffoWaffo";
import { useAlertDialog } from "./dialog/AlertDialogProvider";

const Game: React.FC = () => {
    const {showAlert} = useAlertDialog();
    const [playerId, setPlayerId] = useState("")
    const [playerIdIsSet, setPlayerIdIsSet] = useState(false)
    const [isConnectedToGame, setIsConnectedToGame] = useState(false)
    const [currentGameState, setCurrentGameState] = useState<gameState>()
    const wsConnection: React.MutableRefObject<WebSocket | undefined> = useRef(undefined)
    const gameId = useRef("")
    const nameInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (nameInputRef.current) {
            nameInputRef.current.focus();
        }
      }, []);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            confirmPlayerId();
        }
    };

    function getGame() {
        makeGetRequest("game/" + gameId.current, (jsonResponse: gameState) => {
            setCurrentGameState(jsonResponse);
        }, showAlert);
    }

    function connectToGameRoom(gameId: string, isNewGame: boolean) {
        if (window["WebSocket"]) {
            if (wsConnection.current != undefined && isNewGame) {
                wsConnection.current.close(3333, "new game"); // close because of new game
            }
            wsConnection.current = new WebSocket(WS_BASE_URL + "/api/v1/ws/" + gameId);
            wsConnection.current.onclose = function (event: CloseEvent) {
                console.log("ws connection closed", event.code, event.reason);
                if (event.code == 3333) { // new game triggered close, do not reconnect
                    console.log("new game triggered close");
                    return;
                }
                
                setTimeout(function() {
                    connectToGameRoom(gameId, false);
                  }, 1000);
            };

            wsConnection.current.onmessage = onMessageFromGameRoom;
            setIsConnectedToGame(true);
        }
    }

    function onMessageFromGameRoom(event: MessageEvent<any>) {
        //let message = event.data;
        //console.log(message);
        getGame();
    }

    function playerIdChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setPlayerId(e.target.value)
    }

    function gameDataLoaded(): boolean {
        return typeof currentGameState !== 'undefined'
    }

    function confirmPlayerId() {
        if (playerId.length == 0) {
            showAlert("Please enter non-empty name.");
            return;
        }
        setPlayerIdIsSet(true);
    }

    const classes = useStyles();

    function renderSetPlayerId(): JSX.Element | null {
        if (playerIdIsSet) {
            return null;
        }

        return (
            <div>
                <TextField
                    className={classes.textField}
                    style={{caretColor: "auto"}} label="Player Name" variant="standard" onChange={playerIdChanged} inputRef={nameInputRef} onKeyDown={handleKeyDown}/>
                <IconButton
                    style={{margin: "10px 0px"}}
                    sx={sxStyles.iconButton}
                    color="inherit"
                    onClick= {confirmPlayerId}
                >
                    <CheckCircleIcon/>
                </IconButton>
            </div>
        )
    }

    return (
      <div style={{
        padding: "10px",
        width: "100%"
      }}>
        { !gameDataLoaded() ? <ZaffoWaffo style={{height: "300px", margin: "15px", borderRadius: "10px"}}/> : null }
        {renderSetPlayerId()}
        { !playerIdIsSet || isConnectedToGame ? null :<GameCreateJoin gameId={gameId} connectToGameRoom={connectToGameRoom} playerId={playerId}></GameCreateJoin>}
        { gameDataLoaded() ? <GameView gameState={currentGameState!} playerId={playerId} connectToGameRoom={connectToGameRoom} gameId={gameId}></GameView> : null }
      </div>
    );
  }
  
  export default Game;
  