import { useState } from "react";
import { Button, FormControl, Select, InputLabel, MenuItem, SelectChangeEvent} from '@mui/material';
import { makePostRequest } from "../common/helpers";
import { trumpNumberOptions } from "../common/constants";
import { useAlertDialog } from "./dialog/AlertDialogProvider";

interface GameStartOptionsProps {
    gameId: string
    playerIds: string[]
}

const GameStartOptions: React.FC<GameStartOptionsProps> = ({gameId, playerIds}) => {
    const {showAlert} = useAlertDialog();
    const [dealerIndex, setDealerIndex] = useState(0)
    const [trumpNumber, setTrumpNumber] = useState(2)

    function dealerIndexChanged(e: SelectChangeEvent<number>, _: React.ReactNode) {
        setDealerIndex(e.target.value as number)
    }

    function trumpNumberChanged(e: SelectChangeEvent<number>, _: React.ReactNode) {
        setTrumpNumber(e.target.value as number)
    }

    function startGame() {
        makePostRequest(
            "game/" + gameId + "/start",
            {
                dealerIndex: dealerIndex,
                trumpNumber: trumpNumber
            },
            (response: string) => {
                console.log(response);
            },
            showAlert
         )
    }

    return (
        <div style={{width: "30%", marginLeft: "auto", marginRight: "auto"}}>
            <h2>Game Options</h2>
            <FormControl fullWidth style={{marginBottom: "10px"}}>
                <InputLabel>Dealer</InputLabel>
                <Select
                    value={dealerIndex}
                    label="Dealer"
                    onChange={dealerIndexChanged}
                >
                    {playerIds.map((playerId, index) => <MenuItem value={index}>{playerId}</MenuItem>)}
                    <MenuItem value={-1}>To Be Determined</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth style={{marginBottom: "10px"}}>
                <InputLabel>主 Number</InputLabel>
                <Select
                    value={trumpNumber}
                    label="Trump Number"
                    onChange={trumpNumberChanged}
                >
                    {trumpNumberOptions.map((number, index) => <MenuItem value={index + 2}>{number}</MenuItem>)}
                </Select>
            </FormControl>
            <Button style={{backgroundColor: "#7B4157"}} onClick= {startGame} variant="contained">Start Game</Button>
        </div>
    )
}

export default GameStartOptions