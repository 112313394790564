import { useState, useEffect } from "react";
import { IconButton, Button, FormControl, FormControlLabel, FormGroup, Switch, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from "../common/helpers";
import { useAlertDialog } from "./dialog/AlertDialogProvider";
import { sxStyles } from "../common/styles";
import RefreshIcon from '@mui/icons-material/Refresh';
import LoginIcon from '@mui/icons-material/Login';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateGameDialog from "./CreateGameDialog";
import AddIcon from '@mui/icons-material/Add';

interface GameCreateJoinProps {
    playerId: string,
    gameId: React.MutableRefObject<string>,
    connectToGameRoom: (gameId: string, isNewGame: boolean) => void
}

interface GameIdsResponse {
    gameIds: string[]
}

const GameCreateJoin: React.FC<GameCreateJoinProps> = ({ playerId, gameId, connectToGameRoom }) => {
    const { showAlert } = useAlertDialog();
    const [gameIdsList, setGameIdsList] = useState<string[]>([]);
    const [showCreateGameDialog, setShowCreateGameDialog] = useState<boolean>(false);

    useEffect(() => {
        getGameIds();
    }, []);

    function setGameIdAndJoinRoom(gameIdToJoin: string) {
        gameId.current = gameIdToJoin;
        connectToGameRoom(gameId.current, false);
    }

    function joinGame(gameId: string) {
        makePostRequest("game/" + gameId + "/join", { "playerId": playerId }, (response: string) => {
            setGameIdAndJoinRoom(gameId);
        }, showAlert, shouldAlertJoinError, createRejoinFunction(gameId));
    }

    function shouldAlertJoinError(message: string) {
        return message != "You are already in this game";
    }

    function createRejoinFunction(gameId: string) {
        return function (message: string) {
            if (message == "You are already in this game") {
                // user already in the game, just rejoin
                setGameIdAndJoinRoom(gameId)
            }
        };
    }

    function createGame(playerCount: number, lockedTeams: boolean) {
        makePostRequest("game/", { "creatorPlayerId": playerId, "playerCount": playerCount, "lockedTeams": lockedTeams }, (response: string) => {
            setGameIdAndJoinRoom(response);
        }, showAlert);
    }

    function deleteGame(gameId: string) {
        makeDeleteRequest("game/" + gameId, (response: any) => {
            console.log(response);
            getGameIds();
        }, showAlert);
    }

    function getGameIds() {
        makeGetRequest("game/getAll", (response: GameIdsResponse) => {
            setGameIdsList(response.gameIds)
        }, showAlert);
    }

    function renderActiveGames(): JSX.Element {
        if (gameIdsList.length == 0) {
            return (
                <h2>No games at the moment. Please create a new game or refresh.</h2>
            );
        }

        return (
            <div>
                {gameIdsList.map((gameId) => {
                    return (
                        <div>
                            {gameId}
                            <IconButton
                                style={{ marginLeft: "5px" }}
                                sx={sxStyles.iconButton}
                                color="inherit"
                                onClick={() => joinGame(gameId)}
                                size="large"
                            >
                                <LoginIcon />
                            </IconButton>
                            <IconButton
                                style={{ marginLeft: "1px" }}
                                sx={sxStyles.iconButton}
                                color="inherit"
                                onClick={() => deleteGame(gameId)}
                                size="large"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    );
                })}
            </div>
        );

    }

    return (
        <div>
            <CreateGameDialog open={showCreateGameDialog} onClose={() => setShowCreateGameDialog(false)} createGame={createGame} />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)', /* Create 3 evenly spaced columns */
                    gap: '30px' /* Optional: Adds space between grid items */,
                }}>
                    <div /><div style={{ display: 'flex' }}>
                        <h2>Games</h2>
                        <div style={{ alignSelf: 'center' }}>
                            <IconButton
                                style={{ marginLeft: "5px" }}
                                sx={sxStyles.iconButton}
                                color="inherit"
                                onClick={() => setShowCreateGameDialog(true)}
                                size='large'
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </div><div style={{ alignSelf: 'center' }}>
                        <IconButton
                            //style={{ marginLeft: "20px" }}
                            sx={sxStyles.iconButton}
                            color="inherit"
                            onClick={getGameIds}
                            size='large'
                        >
                            <RefreshIcon />
                        </IconButton>
                    </div>
                </div>
            </div>

            {renderActiveGames()}
        </div >
    )
}

export default GameCreateJoin;