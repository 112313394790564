import { card, PlayGameProps } from "../../common/models"
import { Button, Switch } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { cardsAreEqual, getPlayerIndex, makePostRequest } from "../../common/helpers";
import RoundCards from "./RoundCards";
import PlayerHand from "./PlayerHand";
import AwaitPlayerConfirm from "./AwaitPlayerConfirm";
import { useEffect, useState, useRef } from "react";
import { useAlertDialog } from "../dialog/AlertDialogProvider";
import PreviousRoundsModal from "./PreviousRoundsModal";

const PlayGame: React.FC<PlayGameProps> = ({ gameState, playerId }) => {
    const { showAlert } = useAlertDialog();
    const thisPlayerHasPlayed = gameState.rounds[gameState.currentRound].hasPlayed[getPlayerIndex(gameState, playerId)];
    const prevGameState = useRef(gameState);
    const [autoAdvance, setAutoAdvance] = useState(true);
    const [showPreviousRoundModal, setShowPreviousRoundModal] = useState(false);

    useEffect(() => {
        // prev state was not done, now it is done
        if (autoAdvance && prevGameState.current.rounds[prevGameState.current.currentRound].winnerIndex == -1 && gameState.rounds[gameState.currentRound].winnerIndex != -1) {
            setTimeout(() => {
                readyForNextRound();
            }, 1000);
        }

        prevGameState.current = gameState;
    }, [gameState])

    function readyForNextRound() {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            showAlert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "readyfornextround",
                playerIndex: playerIndex,
            },
            (response: string) => console.log(response),
            showAlert
        )
    }

    function addCardToRound(card: card) {
        if (gameState.dealerPartnerIndex == -1 && cardsAreEqual(card, gameState.calledCard)) {
            showAlert("This card was called, you will join the dealer's team if you play this.")
        }
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            showAlert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "addcardtoround",
                playerIndex: playerIndex,
                card: card,
            },
            (response: string) => console.log(response),
            showAlert
        )
    }

    function playerWonRound(winningPlayerId: string) {
        let playerIndex = getPlayerIndex(gameState, winningPlayerId);
        if (playerIndex === -1) {
            showAlert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "playerwonround",
                playerIndex: playerIndex,
            },
            (response: string) => console.log(response),
            showAlert
        )
    }

    function showCurrentHand(withOptions: boolean): JSX.Element | null {
        return (
            <div>
                <PlayerHand gameState={gameState} playerId={playerId} onClick={withOptions ? addCardToRound : () => { }}></PlayerHand>
            </div>
        )
    }

    function showReadyForNextRoundButton(): JSX.Element | null {
        if (gameState.rounds[gameState.currentRound].winnerIndex == -1) {
            return null;
        }

        if (gameState.rounds[gameState.currentRound].readyForNextRound[getPlayerIndex(gameState, playerId)]) {
            return (
                <div>
                    <AwaitPlayerConfirm
                        gameState={gameState}
                        playerId={playerId}
                        awaitingText={gameState.playerHands[0].length == 0 ? "Waiting for others to be ready for the 底牌..." : "Waiting for others to be ready for next round..."}
                        playerReadyStatus={gameState.rounds[gameState.currentRound].readyForNextRound}
                    ></AwaitPlayerConfirm>
                </div>
            )

        }

        if (autoAdvance) {
            return null;
        }

        var text = gameState.playerHands[0].length == 0 ? "See 底牌 and results" : "Ready for next round";
        return (
            <Button style={{ backgroundColor: "#7B4157" }} onClick={readyForNextRound} variant="contained">{text}</Button>
        )
    }

    function showSelectWinnerButtons(): JSX.Element | null {
        if (gameState.rounds[gameState.currentRound].pointsWon == -1 || gameState.rounds[gameState.currentRound].winnerIndex != -1) { // not all people played or winner has been picked
            return null;
        }
        return (
            <div>
                {gameState.playerIds.map(playerId => {
                    return (
                        <Button style={{ backgroundColor: "#7B4157", margin: "5px" }} onClick={() => playerWonRound(playerId)} variant="contained">{playerId} Won</Button>
                    )
                })}
            </div>

        )
    }

    function resetRound() {
        makePostRequest(
            "game/" + gameState.gameId + "/resetRound",
            {},
            (response: string) => console.log(response),
            showAlert
        )
    }

    function onAutoAdvanceToggled(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        setAutoAdvance(checked);
        if (checked) {
            if (gameState.rounds[gameState.currentRound].winnerIndex != -1 && !gameState.rounds[gameState.currentRound].readyForNextRound[getPlayerIndex(gameState, playerId)]) {
                readyForNextRound();
            }
        }
    }

    return (
        <div>
            <PreviousRoundsModal open={showPreviousRoundModal} onClose={() => setShowPreviousRoundModal(false)} gameState={gameState} playerId={playerId} />
            {gameState.currentRound != 0 ? <Button style={{ backgroundColor: "#7B4157", marginTop: "10px", marginRight: "10px" }} onClick={() => setShowPreviousRoundModal(true)} variant="contained">Show Previous Round</Button> : null}
            {playerId == gameState.playerIds[0] ? <Button style={{ backgroundColor: "#7B4157", marginTop: "10px" }} onClick={resetRound} variant="contained">Reset Round</Button> : null}
            <RoundCards gameState={gameState} playerId={playerId} round={gameState.currentRound}></RoundCards>
            {showSelectWinnerButtons()}
            {showReadyForNextRoundButton()}
            {showCurrentHand(!thisPlayerHasPlayed)}
            <FormGroup sx={{ alignContent: "center" }}>
                <FormControlLabel control={<Switch checked={autoAdvance} onChange={onAutoAdvanceToggled} />} label="AutoAdvance" />
            </FormGroup>
        </div>
    )
}

export default PlayGame;
