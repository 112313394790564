import { useState } from "react";
import { getCardSorter } from "../../common/helpers";
import { PlayGameProps, card } from "../../common/models"
import Card from "./Card";
import PointInfoDisplay from "./PointInfoDisplay";
import { Button } from "@mui/material";
import PreviousRoundsModal from "./PreviousRoundsModal";

const EndOfGame: React.FC<PlayGameProps> = ({ gameState, playerId }) => {
    const [showPreviousRoundModal, setShowPreviousRoundModal] = useState(false);

    function isCardWorthPoints(card: card): boolean {
        return card.value == 5 || card.value == 10 || card.value == 13
    }
    return (
        <div style={{ margin: "10px" }}>
            <PreviousRoundsModal open={showPreviousRoundModal} onClose={() => setShowPreviousRoundModal(false)} gameState={gameState} playerId={playerId} showAllRounds={true} />
            <h1>Game End</h1>
            <PointInfoDisplay gameState={gameState} playerId={playerId}></PointInfoDisplay>
            <div>
                <Button style={{ backgroundColor: "#7B4157", margin: "10px" }} onClick={() => setShowPreviousRoundModal(true)} variant="contained">Show All Rounds</Button>
                <h2>底牌:</h2>
                {gameState.dipai.sort(getCardSorter(gameState)).map(card => <Card card={card} highlight={isCardWorthPoints(card)}></Card>)}
            </div>
        </div>
    )
}

export default EndOfGame;
