import { Button } from "@mui/material";
import { getCardSorter, getPlayerIndex, makePostRequest } from "../../common/helpers";
import { card, gameState, PlayGameProps } from "../../common/models"
import Card from "./Card";
import { dealerTeamColor, nonDealerTeamColor } from "../../common/constants";
import { useAlertDialog } from "../dialog/AlertDialogProvider";

interface RoundCardsProps {
    gameState: gameState;
    playerId: string;
    round: number;
}
const RoundCards: React.FC<RoundCardsProps> = ({ gameState, playerId, round }) => {
    const { showAlert } = useAlertDialog();

    const isValidRound = round >= 0 && round < gameState.rounds.length
    const currentRound = isValidRound ? gameState.rounds[round] : gameState.rounds[0];
    const currentPlayerIndex = getPlayerIndex(gameState, playerId);

    function removeCardFromRound(card: card) {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            showAlert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "removecardfromround",
                playerIndex: playerIndex,
                card: card,
            },
            (response: string) => console.log(response),
            showAlert
        )
    }

    function playCards() {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            showAlert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "playcardsforround",
                playerIndex: playerIndex,
            },
            (response: string) => console.log(response),
            showAlert
        )
    }

    function shouldShowCards(playedIndex: number): boolean {
        return currentRound.hasPlayed[playedIndex];
    }

    function haveAllPlayersPlayed(): boolean {
        for (var i = 0; i < currentRound.hasPlayed.length; i++) {
            if (!currentRound.hasPlayed[i]) {
                return false;
            }
        }

        return true;
    }

    function renderCardsForPlayer(playerIndex: number): JSX.Element[] | null {
        if (shouldShowCards(playerIndex)) {
            return (
                currentRound.cardsPlayed[playerIndex].sort(getCardSorter(gameState)).map(card => <Card card={card}></Card>)
            )
        }
        if (currentPlayerIndex == playerIndex) {
            return (
                currentRound.cardsPlayed[playerIndex].sort(getCardSorter(gameState)).map(card => <Card transparent card={card} onClick={() => removeCardFromRound(card)}></Card>)
            )
        }
        return null;
    }

    function displayRoundCardsForPlayer(playerIndex: number): JSX.Element {
        let playerNameHeader = gameState.playerIds[playerIndex];
        let pointsWonHeader = "";
        let border = "2px solid #A49393";
        // add this padding to align with 5px border
        let padding = "3px";
        if (playerIndex == gameState.activePlayerIndex && !haveAllPlayersPlayed()) {
            border = "5px solid #7B4157";
            padding = "0px";
        }
        else if (haveAllPlayersPlayed() && currentRound.winnerIndex == playerIndex) {
            border = "5px solid black";
            padding = "0px";
            pointsWonHeader = " +" + currentRound.pointsWon + "分";
        }

        let isTurnToPlay = gameState.activePlayerIndex == currentPlayerIndex && gameState.activePlayerIndex == playerIndex && !haveAllPlayersPlayed();
        return (
            <div style={{ margin: "5px", border: border, width: "100%", minHeight: 200, borderRadius: "10px", backgroundColor: getBackgroundColorForPlayer(playerIndex), padding: padding }}>
                {isTurnToPlay ?
                    <div style={{ margin: "10px" }}><Button style={{ backgroundColor: "#7B4157" }} onClick={playCards} variant="contained">Play Cards</Button></div>
                    :
                    <h3>{playerNameHeader}<span style={{ color: "green" }}>{pointsWonHeader}</span></h3>
                }
                {renderCardsForPlayer(playerIndex)}
            </div>
        )
    }

    function getBackgroundColorForPlayer(playerIndex: number): string {
        if (gameState.dealerPartnerIndex == -1) {
            if (playerIndex == gameState.dealerIndex) {
                return dealerTeamColor;
            }

            return "";
        }

        if (playerIndex == gameState.dealerIndex || playerIndex == gameState.dealerPartnerIndex) {
            return dealerTeamColor;
        }

        return nonDealerTeamColor;
    }

    function renderDisplayForPlayerCount(): JSX.Element {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {gameState.playerIds.map((_, index) => displayRoundCardsForPlayer(index))}
                </div>
            </div>
        )
    }

    return (
        isValidRound ? <div style={{ margin: "10px", textAlign: 'center' }}>
            <div>
                {renderDisplayForPlayerCount()}
            </div>
        </div> : null
    )
}

export default RoundCards;
