import { Button } from "@mui/material";
import { getPlayerIndex, getSuitImage, isPlayingJoker, makePostRequest } from "../../common/helpers";
import {PlayGameProps, card, } from "../../common/models"
import { useAlertDialog } from "../dialog/AlertDialogProvider";

const DeclareTrump: React.FC<PlayGameProps> = ({gameState, playerId}) => {
    const {showAlert} = useAlertDialog();

    const playerHand: card[] = gameState.playerHands[getPlayerIndex(gameState, playerId)];

    const suits = ["clubs", "diamonds", "hearts", "spades"];

    function declareTrumpCall(suit: string, secure: boolean) {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            showAlert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "declaretrump",
                playerIndex: playerIndex,
                trumpSuit: suit,
                isSecure: secure,
            },
            (response: string) => console.log(response),
            showAlert
        )
    }

    function getTrumpNumberCountForSuit(suit: string): number {
        let count = 0;
        for (var i = 0; i < playerHand.length; i++) {
            let card = playerHand[i];
            if (card.suit === suit && card.value == gameState.trumpNumber) {
                count++;
            }
        }
        return count;
    }

    function declareTrump(suit: string) {
        declareTrumpCall(suit, false);
    }

    function secureTrump(suit: string) {
        declareTrumpCall(suit, true);
    }


    function declareSuitEnabled(suit: string): boolean {
        if (isPlayingJoker(gameState)) {
            return false;
        }

        if (gameState.trumpSecured) {
            return false;
        }

        if (gameState.trumpSuit !== "undecided") {
            return false;
        }
        let suitCardCount =  getTrumpNumberCountForSuit(suit);
        if (suitCardCount == 0) {
            return false;
        }
        
        return true;
    }

    function secureSuitEnabled(suit: string): boolean {
        if (isPlayingJoker(gameState)) {
            return false;
        }
        
        if (gameState.trumpSecured) {
            return false;
        }

        if (gameState.trumpDeclarePlayerIndex === getPlayerIndex(gameState, playerId)) { //player who declared and only secure same suit
            if (gameState.trumpSuit != suit) {
                return false;
            }
        }

        let suitCardCount =  getTrumpNumberCountForSuit(suit);
        if (suitCardCount != 2) {
            return false;
        }
        
        return true;
    }

    function secureButtonText(): string {
        if (gameState.trumpSuit == "undecided") {
            return "Secure 主 as";
        }

        if (gameState.trumpDeclarePlayerIndex == getPlayerIndex(gameState, playerId)) {
            return "Secure 主 as";
        }

        return "Change 主 to";
    }

    function renderDeclareSuitOptions(): JSX.Element | null {
        let declarableSuitCount = 0;
        for(var suit of suits) {
            if (declareSuitEnabled(suit)) {
                declarableSuitCount++;
            }
        }

        if (declarableSuitCount == 0) {
            return null;
        }

        return (
            <div>
                <span>Declare 主 as</span>
                {suits.map(suit => {
                return (
                    declareSuitEnabled(suit) ? 
                        <img src={getSuitImage(suit)} onClick={() => declareTrump(suit)} style={{margin: "10px", width: "50px", verticalAlign: "middle"}} />
                        : null
                )})}
            </div>
        )
    }

    function renderSecureSuitOptions(): JSX.Element | null {
        let securableSuitCount = 0;
        for(var suit of suits) {
            if (secureSuitEnabled(suit)) {
                securableSuitCount++;
            }
        }

        if (securableSuitCount == 0) {
            return null;
        }

        return (
            <div>
                <span>{secureButtonText()}</span>
                {suits.map(suit => {
                return (
                    secureSuitEnabled(suit) ? 
                        <img src={getSuitImage(suit)} onClick={() => secureTrump(suit)} style={{margin: "10px", width: "50px", verticalAlign: "middle"}} />
                        : null
                )})}
            </div>
        )
    }

    return (
        <div>
            {renderDeclareSuitOptions()}
            {renderSecureSuitOptions()}
        </div>
    )
}

export default DeclareTrump;
