import bags from "../images/zafwafsnow.png";

interface ZaffoWaffoProps {
    style: React.CSSProperties
}

const ZaffoWaffo: React.FC<ZaffoWaffoProps> = ({style}) => {
    return (
        <img style={style} src={bags} alt="Zaffo"/>
    )
}

export default ZaffoWaffo;
