import './App.css';
import { AlertDialogProvider } from './components/dialog/AlertDialogProvider';
import Game from './components/Game'

function App() {
  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      minHeight: "100vh",
      textAlign: "center",
      backgroundColor: "#F3E4E2",
      caretColor: "transparent"
    }}><AlertDialogProvider><Game/></AlertDialogProvider></div>
  );
}

export default App;
