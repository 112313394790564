import { makeStyles } from "@material-ui/core/styles";

export const colors = {
    backgroundColor: "#F3E4E2",
    slightlyDarkerBackgroundColor: '#E8CCC9',
    buttonColor: "#7B4157"
}
export const useStyles = makeStyles({
    textField: {
        // input label when focused
        "& label.Mui-focused": {
            color: colors.buttonColor
        },
        // focused color for input with variant='standard'
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.buttonColor
        },
        '& .MuiInput-underline': {
            '&:before': {
                borderBottom: '2px solid transparent',  // Border color before focus
            },
            '&:hover:not(.Mui-disabled):before': {
                borderBottomColor: colors.slightlyDarkerBackgroundColor,
                //borderBottom: '2px solid blue',  // Border color on hover
            },
        }
    },
});

export const sxStyles = {
    iconButton: {
        //backgroundColor: 'green', // Custom background color
        color: colors.buttonColor, // Icon color
        '&:hover': {
            backgroundColor: colors.slightlyDarkerBackgroundColor, // Change background color on hover
        },
    }
}

