import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { colors } from '../../common/styles';

interface AlertDialogProps {
  open: boolean;
  content: string;
  title: string;
  onClose: () => void;
}

const AlertDialog: React.FC<AlertDialogProps> = ({ open, content, title, onClose }) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the form submit or other default behavior
      onClose(); // Close the dialog
    }
  };

  return (
    <Dialog open={open} onClose={onClose} onKeyDown={handleKeyDown} transitionDuration={{exit: 500}} maxWidth="xs" fullWidth sx={{
      '& .MuiDialogTitle-root': {
        backgroundColor: colors.backgroundColor, // Blue background for title
        padding: '10px', // Padding for title
      },
      '& .MuiDialogContent-root': {
        backgroundColor: colors.backgroundColor, // Light grey background for content
        padding: '10px 20px', // Padding inside content
      },
      '& .MuiDialogActions-root': {
        backgroundColor: colors.backgroundColor, // Light blue background for actions
        padding: '5px', // Padding for actions
      },
    }}
    PaperProps={{ sx: { borderRadius: "15px" } }}>
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      <DialogContent>
        <p>{content}</p>
      </DialogContent>
      <DialogActions>
        <Button style={{backgroundColor: "#7B4157", margin: "10px"}} onClick={onClose} variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
