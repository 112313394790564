import React, { createContext, useState, useContext, ReactNode } from 'react';
import AlertDialog from './AlertDialog';

// Define the types for the context state
interface AlertDialogContextType {
    showAlert: (content: string) => void;
    closeDialog: () => void;
}

const AlertDialogContext = createContext<AlertDialogContextType | undefined>(undefined);

export const useAlertDialog = (): AlertDialogContextType => {
    const context = useContext(AlertDialogContext);
    if (!context) {
      throw new Error('useDialog must be used within a DialogProvider');
    }
    return context;
};

// Define the type for the DialogProvider component's props
interface AlertDialogProviderProps {
    children: ReactNode;
}

export const AlertDialogProvider: React.FC<AlertDialogProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const showAlert = (content: string) => {
    setDialogContent(content);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <AlertDialogContext.Provider value={{ showAlert, closeDialog }}>
      {children}
      <AlertDialog open={isOpen} onClose={closeDialog} content={dialogContent} title={""} />
    </AlertDialogContext.Provider>
  );
};
