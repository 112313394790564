import {PlayGameProps, card} from "../../common/models"
import {getPlayerIndex, makePostRequest, getCardSorter, getSuitFilter} from "../../common/helpers";
import { Box, Button, Modal} from '@mui/material';
import { useState} from "react";
import PlayerHand from "./PlayerHand";
import Card from "./Card";
import { useAlertDialog } from "../dialog/AlertDialogProvider";

const CallFriend: React.FC<PlayGameProps> = ({gameState, playerId}) => {
    const {showAlert} = useAlertDialog();

    const [callCard, setCallCard] = useState<card>({
        id: 1,
        suit: "blank",
        value: 2
    })

    const [showCallCardModal, setShowCallCardModal] = useState(false)
    
    

    const suits = ["clubs", "diamonds", "hearts", "spades"];
    const cardList: card[] = [];
    for(var i = 0; i < suits.length; i++) {
        for (var j = 2; j <= 14; j++) {
            cardList.push({id: 1, suit: suits[i], value: j})
        }
    }

    function getSuitHand(suit: string): card[] {
        return (
            cardList.filter(getSuitFilter(gameState, suit))
        );
    }

    function callFriend() {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            showAlert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "callfriend",
                playerIndex: playerIndex,
                card: callCard,
            },
            (response: string) => console.log(response),
            showAlert
        )
    }

    function waitingForDealer(): JSX.Element {
        return (
            <div>
                <h3>Waiting for dealer to 叫...</h3>
            </div>
        )
    }

    const dipai = gameState.dipai;

    function showCurrentHand(): JSX.Element {
        return (
            <div>
                <h2>你的牌</h2>
                <PlayerHand gameState={gameState} playerId={playerId}></PlayerHand>
            </div>
        )
    }

    function showDiPai(): JSX.Element {
        return (
            <div>
                <h2>底牌</h2>
                {
                    dipai.sort(getCardSorter(gameState)).map(card => <Card card={card}></Card>)
                }
            </div>
        )
    }

    function showAllCardsWithOptions(): JSX.Element {
        return (
            <div>
                {suits.map((suit) => {
                    if (suit == gameState.trumpSuit) {
                        return (null);
                    }

                    return (
                        <div>
                        {getSuitHand(suit).map((card) => {
                            return <Card card={card} onClick={() => setCallCardWithSuitAndValue(card.suit, card.value)}></Card>
                        })}
                        </div>
                    )
                })}
            </div>
        )
    }

    function setCallCardWithSuitAndValue(suit: string, value: number) {
        setCallCard({
            id: 1,
            suit: suit,
            value: value
        })

        closeModal()

    }

    function openModal() {
        setShowCallCardModal(true)
    }

    function closeModal() {
        setShowCallCardModal(false)
    }

    return (
        <div style={{margin: "10px"}}>
            <Modal
                open={showCallCardModal}
                onClose={closeModal}
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}
            >
                <div>
                {showAllCardsWithOptions()}
                </div>
            </Modal>
            {getPlayerIndex(gameState, playerId) == gameState.dealerIndex ? 
            <div>
            <h2>你想叫的牌</h2>
            <Card card={callCard} onClick={openModal}></Card>
            <div style={{margin: "5px"}}>
            <Button style={{backgroundColor: "#7B4157"}} onClick={callFriend} variant="contained">叫</Button>
            </div>
            {showDiPai()}
            {showCurrentHand()}
            </div>
            :
            <div>
                {waitingForDealer()}
                <PlayerHand gameState={gameState} playerId={playerId}></PlayerHand>
            </div>
            }
        </div>
    )
}

export default CallFriend;
