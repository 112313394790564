import {PlayGameProps, card} from "../../common/models"
import {getPlayerIndex, makePostRequest, getCardSorter} from "../../common/helpers";
import { Button } from "@mui/material";
import PlayerHand from "./PlayerHand";
import Card from "./Card";
import { useAlertDialog } from "../dialog/AlertDialogProvider";

const DiPai: React.FC<PlayGameProps> = ({gameState, playerId}) => {
    const {showAlert} = useAlertDialog();

    function takeDiPai() {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            showAlert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "takedipai",
                playerIndex: playerIndex,
            },
            (response: string) => console.log(response),
            showAlert
        )
    }

    function putInDiPai(card: card) {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            showAlert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "putindipai",
                playerIndex: playerIndex,
                card: card,
            },
            (response: string) => console.log(response),
            showAlert
        )
    }

    function diPaiToHand(card: card) {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            showAlert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "dipaitohand",
                playerIndex: playerIndex,
                card: card,
            },
            (response: string) => console.log(response),
            showAlert
        )
    }

    function lockInDiPai() {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            showAlert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "lockindipai",
                playerIndex: playerIndex,
            },
            (response: string) => console.log(response),
            showAlert
        )
    }

    function showOriginalDiPai(): JSX.Element | null {
        if (gameState.deck.length == 0) {
            return null;
        }
        return (
            <div>
            <h2>底牌</h2>
            <div>
                {gameState.deck.sort(getCardSorter(gameState)).map(card => <Card card={card}></Card>)}
            </div>
            <Button style={{backgroundColor: "#7B4157", margin: "10px"}} onClick={takeDiPai} variant="contained">Take 底牌</Button>
            <PlayerHand gameState={gameState} playerId={playerId}></PlayerHand>
            </div>
        )
    }

    function waitingForDealer(): JSX.Element {
        return (
            <div>
                <h3>Waiting for dealer to handle 底牌...</h3>
            </div>
        )
    }

    const playerHand = gameState.playerHands[getPlayerIndex(gameState, playerId)];
    const dipai = gameState.dipai;

    function showCurrentHandWithOptions(): JSX.Element | null {
        if (gameState.deck.length != 0) {
            return null;
        }

        return (
            <div>
                <h2>Hand</h2>
                <PlayerHand gameState={gameState} playerId={playerId} onClick={putInDiPai}></PlayerHand>
            </div>
        )
    }

    function showCurrentDiPaiWithOptions(): JSX.Element | null {
        if (gameState.deck.length != 0) {
            return null;
        }
        
        if (gameState.dipai.length == 0) {
            return (
                <div>
                    <h2>底牌</h2>
                    <h3>Click on the cards you want to add to your 底牌</h3>
                </div>
                
            )
        }

        return (
            <div>
                <h2>底牌</h2>
                {
                    dipai.sort(getCardSorter(gameState)).map(card => <Card card={card} onClick={() => diPaiToHand(card)}></Card>)
                }
            </div>
        )
    }

    function showLockInDiPaiButton(): JSX.Element | null {
        if (gameState.deck.length != 0) {
            return null;
        }
        return (
            <Button style={{backgroundColor: "#7B4157"}} onClick={lockInDiPai} variant="contained">Lock in 底牌</Button>
        )
    }

    return (
        <div style={{margin: "10px"}}>
            {getPlayerIndex(gameState, playerId) == gameState.dealerIndex ? 
            <div>
            {showLockInDiPaiButton()}
            <br></br>
            {showOriginalDiPai()}
            {showCurrentDiPaiWithOptions()}
            {showCurrentHandWithOptions()}
            </div>
            :
            <div>
                {waitingForDealer()}
                <PlayerHand gameState={gameState} playerId={playerId}></PlayerHand>
            </div>
            }
        </div>
    )
}

export default DiPai;
