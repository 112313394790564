import {PlayGameProps} from "../../common/models"
import PointInfoDisplay from "./PointInfoDisplay";
import TrumpInfoDisplay from "./TrumpInfoDisplay";
import '../../styles/Header.css'
import ZaffoWaffo from "../ZaffoWaffo";

const Header: React.FC<PlayGameProps> = ({gameState, playerId}) => {
    return (
        <div className="container">
            <div style={{margin: "auto"}}><TrumpInfoDisplay gameState={gameState} playerId={playerId}></TrumpInfoDisplay></div>
            <ZaffoWaffo style={{margin: "10px auto", height: "150px", borderRadius: "10px"}}/>
            <div style={{margin: "auto"}}><PointInfoDisplay gameState={gameState} playerId={playerId}></PointInfoDisplay></div>
        </div>
    )
}

export default Header;