import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, SelectChangeEvent } from '@mui/material';
import { colors } from '../common/styles';

interface CreateGameDialogProps {
    open: boolean;
    onClose: () => void;
    createGame: (playerCount: number, lockedTeams: boolean) => void;
}

const CreateGameDialog: React.FC<CreateGameDialogProps> = ({ open, onClose, createGame }) => {
    const [playerCount, setPlayerCount] = useState<number>(4);
    const [lockedTeams, setLockedTeams] = useState(false);

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the form submit or other default behavior
            onClose(); // Close the dialog
        }
    };

    function playerCountChanged(e: SelectChangeEvent<number>, _: React.ReactNode) {
        setPlayerCount(e.target.value as number);
    }

    function onLockedTeamsToggled(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        setLockedTeams(checked);
    }

    function onConfirm() {
        createGame(playerCount, lockedTeams);
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose} onKeyDown={handleKeyDown} transitionDuration={{ exit: 500 }} maxWidth="xs" fullWidth sx={{
            '& .MuiDialogTitle-root': {
                backgroundColor: colors.backgroundColor, // Blue background for title
                padding: '10px', // Padding for title
            },
            '& .MuiDialogContent-root': {
                backgroundColor: colors.backgroundColor, // Light grey background for content
                padding: '10px 20px', // Padding inside content
            },
            '& .MuiDialogActions-root': {
                backgroundColor: colors.backgroundColor, // Light blue background for actions
                padding: '0px', // Padding for actions
            },
        }}
            PaperProps={{ sx: { borderRadius: "15px" } }}>
            <DialogTitle><div style={{ display: "flex", justifyContent: "center" }}>Create Game</div></DialogTitle>
            <DialogContent>
                <FormControl fullWidth style={{ margin: "10px 0px" }}>
                    <InputLabel>Player Count</InputLabel>
                    <Select
                        value={playerCount}
                        label="Player Count"
                        onChange={playerCountChanged}
                        style={{ textAlign: 'center' }}
                    >
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                    </Select>
                </FormControl>
                <FormGroup sx={{ alignContent: "center" }}>
                    <FormControlLabel control={<Switch checked={lockedTeams} onChange={onLockedTeamsToggled} />} label="Locked Teams" />
                </FormGroup>
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
                <Button style={{ backgroundColor: "#7B4157", margin: "10px" }} onClick={onClose} variant="contained">
                    Cancel
                </Button>
                <Button style={{ backgroundColor: "#7B4157", margin: "10px" }} onClick={onConfirm} variant="contained">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateGameDialog;
