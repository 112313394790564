import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, SelectChangeEvent } from '@mui/material';
import { colors } from '../../common/styles';
import { gameState } from '../../common/models';
import RoundCards from './RoundCards';

interface PreviousRoundsModalProps {
    open: boolean;
    onClose: () => void;
    gameState: gameState;
    playerId: string;
    showAllRounds?: boolean;
}

const PreviousRoundsModal: React.FC<PreviousRoundsModalProps> = ({ open, onClose, gameState, playerId, showAllRounds }) => {
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the form submit or other default behavior
            onClose(); // Close the dialog
        }
    };

    return (
        <Dialog open={open} onClose={onClose} onKeyDown={handleKeyDown} transitionDuration={{ exit: 500 }} maxWidth={false} fullWidth sx={{
            '& .MuiDialogTitle-root': {
                backgroundColor: colors.backgroundColor, // Blue background for title
                padding: '10px', // Padding for title
            },
            '& .MuiDialogContent-root': {
                backgroundColor: colors.backgroundColor, // Light grey background for content
                padding: '10px 20px', // Padding inside content
            },
            '& .MuiDialogActions-root': {
                backgroundColor: colors.backgroundColor, // Light blue background for actions
                padding: '0px', // Padding for actions
            },
        }}
            PaperProps={{ sx: { borderRadius: "15px" } }}>
            <DialogTitle><div style={{ display: "flex", justifyContent: "center" }}>{showAllRounds ? "Previous Rounds" : "Previous Round"}</div></DialogTitle>
            <DialogContent>
                {showAllRounds ?
                    <div>
                        {gameState.rounds.map((_, index) => (<RoundCards gameState={gameState} playerId={playerId} round={index} />))}
                    </div> :
                    <RoundCards gameState={gameState} playerId={playerId} round={gameState.currentRound - 1} />}
            </DialogContent>
            <DialogActions>
                <Button style={{ backgroundColor: "#7B4157", margin: "10px" }} onClick={onClose} variant="contained">
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PreviousRoundsModal;
